import s from './Vendinds.module.scss'
import cn from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import printersPath from '../../assets/images/printers.png'
import laundriesPath from '../../assets/images/laundries.png'
import showersPath from '../../assets/images/showers.png'
import layoutsPath from '../../assets/images/layouts.png'
import retailsPath from '../../assets/images/retail.png'
import carsPath from '../../assets/images/cars.png'
import rightArrowPath from '../../assets/icons/arrowRight.svg'
import gsap from 'gsap'
import { useGSAP } from '@gsap/react'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(useGSAP)
gsap.registerPlugin(ScrollTrigger)

const Vendinds = () => {
  const VENDING_TYPES = [
    {
      id: 'printers',
      image: printersPath,
      text: 'Принтеры самообслуживания',
    },
    {
      id: 'lauundries',
      image: laundriesPath,
      text: 'Прачечные самообслуживания',
    },
    {
      id: 'showers',
      image: showersPath,
      text: 'Душевые самообслуживания',
    },
    {
      id: 'layouts',
      image: layoutsPath,
      text: 'Макеты с управлением',
    },
    {
      id: 'retails',
      image: retailsPath,
      text: 'Выдача штучного товара',
    },
    {
      id: 'cars',
      image: carsPath,
      text: 'Мойки самообслуживания',
    },
  ]

  return (
    <section className={s.container}>
      <div className={s.content}>
        <h2 className={s.title}>
          <span className={s.accent}>Юниметрик</span> - универсальная платформа
          для&nbsp;разных систем самообслуживания!
        </h2>
        <div className={s.types}>
          {VENDING_TYPES.map((element) => (
            <div className={s.type} key={element.id}>
              <img
                src={element.image}
                alt={element.text}
                className={s.typeImage}
              />
              <span className={s.typeText}>{element.text}</span>
            </div>
          ))}
        </div>
        <div className={s.swiperContainer}>
          <Swiper
            pagination={{ clickable: true }}
            modules={[Navigation, Pagination]}
            className={cn(s.swiper)}
            // centeredSlides
            touchRatio={2}
            grabCursor
            mousewheel={{ sensitivity: 1 }}
            centeredSlidesBounds
            loop
            navigation={{
              prevEl: `.${s.prev}`,
              nextEl: `.${s.next}`,
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              500: {
                slidesPerView: 2,
              },
            }}
            // slidesPerView={'auto'}
            observer
            observeParents
            observeSlideChildren
            // autoHeight
            // spaceBetween={50}
          >
            {VENDING_TYPES.map((element) => (
              <SwiperSlide key={element.id} className={s.slide}>
                <div className={s.slideImageContainer}>
                  <img
                    src={element.image}
                    alt={element.text}
                    className={s.slideImage}
                  />
                </div>
                <span className={s.sliderText}>{element.text}</span>
              </SwiperSlide>
            ))}
            <div className={cn(s.prev, 'prev')}></div>
            <div className={cn(s.next, 'next')}></div>
          </Swiper>
        </div>
      </div>
    </section>
  )
}

export default Vendinds
