import s from './Info.module.scss'
import cn from 'classnames'
import addressesPath from '../../assets/images/addresses.png'
import analyticsPath from '../../assets/images/analytics.png'
import devicesPath from '../../assets/images/devices.png'
import journalPath from '../../assets/images/journal.png'
import { HashLink as Link } from 'react-router-hash-link'
import { DEMO_LINK } from '../../constatns'

const Info = () => {
  return (
    <section className={s.container}>
      <div className={s.content}>
        <div className={s.informationContainer}>
          {' '}
          <h2 className={s.title}>
            Платформа для&nbsp;систем самообслуживания,
            созданная&nbsp;для&nbsp;себя.{' '}
            <span className={s.accent}>
              теперь&nbsp;доступна&nbsp;для&nbsp;вас
            </span>
          </h2>
          <div className={s.textContainer}>
            <p className={s.text}>
              Общество с&nbsp;ограниченной ответственностью «Юниметрик» является
              владельцем сайта unimetriq.com и занимается разработкой
              программного обеспечения для&nbsp;управления вендинговым
              оборудованием и системами самообслуживания.
            </p>
            <p className={s.text}>
              Мы&nbsp;уже&nbsp;13&nbsp;лет работаем с&nbsp;вендингом и
              зарабатываем на&nbsp;нем. На&nbsp;базе разработанного нами
              программного обеспечения запущено более 520 собственных устройств
              и еще&nbsp;около 2000 партнерских.
            </p>
            <p className={s.text}>
              Юниметрик помогает партнерам зарабатывать на&nbsp;прачечных,
              копировальных центрах, продаже штучного товара и развлечений
              в&nbsp;формате самообслуживания.
            </p>
          </div>
          <Link to={DEMO_LINK} className={s.button}>
            Перейти в демо-кабинет
          </Link>
        </div>
        <div className={s.grid}>
          <div className={cn(s.column, s.column_first)}>
            <img
              src={addressesPath}
              alt="Страница адресов"
              className={s.addresses}
            />
            <img
              src={analyticsPath}
              alt="Страница аналитики"
              className={s.analytics}
            />
          </div>
          <div className={cn(s.column, s.column_second)}>
            <img
              src={devicesPath}
              alt="Страница адресов"
              className={s.addresses}
            />
            <img
              src={journalPath}
              alt="Страница адресов"
              className={s.addresses}
            />
          </div>
        </div>

        {/* <Link
          to={DEMO_LINK}
          className={cn(s.button, s.button_mobile)}
        >
          Перейти в демо-кабинет
        </Link> */}
      </div>
    </section>
  )
}

export default Info
