import { useEffect, useState } from 'react'
import './App.css'
import Header from './components/Header/Header'
import HamburgerMenu from './components/HamburgerMenu/HamburgerMenu'
import Footer from './components/Footer/Footer'
import { Routes, Route, Navigate } from 'react-router-dom'
import Main from './components/Main/Main'
import Documentation from './components/Documentation/Documentation'
import { useLocation } from 'react-router-dom'
import ScrollToTopButton from './components/ScrollToTopButton/ScrollToTopButton'
import Contacts from './components/Contacts/Contacts'

function App() {
  const [openMenu, setOpenMenu] = useState(false)
  useEffect(() => {
    if (openMenu) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }

    return () => {
      document.body.style.overflow = ''
    }
  }, [openMenu])

  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <div className="App">
      <Header setOpenMenu={setOpenMenu} />
      <Routes>
        <Route path={'/'} element={<Main />} />
        <Route path={'/documents'} element={<Documentation />} />
        <Route path={'/contacts'} element={<Contacts />} />
        <Route path="*" element={<Navigate to={'/'} />} />
      </Routes>
      <ScrollToTopButton />
      <HamburgerMenu openMenu={openMenu} setOpenMenu={setOpenMenu} />
      <Footer />
    </div>
  )
}

export default App
