import s from './Technologies.module.scss'
import cn from 'classnames'

const Technologies = () => {
  const TECHNOLOGIES = [
    'JavaScript',
    'TypeScript',
    'React',
    'PHP',
    'Npm',
    'NodeJS',
    'Debian',
    'Laravel',
    'MySQL',
  ]
  const VENDINGS = [
    'Прачечные',
    'Мойки',
    'Принтеры',
    'Душевые',
    'Кофекорнеры',
    'Штучный товар',
  ]
  return (
    <section className={s.container}>
      <div className={s.content}>
        <h2 className={s.title}>Технологии</h2>
        <div className={s.cardsContainer}>
          <div className={s.card}>
            <h3 className={s.cardTitle}>
              {' '}
              <span className={cn(s.icon, s.icon_technologies)}></span>
              Технологический стек
            </h3>
            <div className={s.cardItems}>
              {TECHNOLOGIES.map((el) => (
                <span key={el} className={s.cardItem}>
                  {el}
                </span>
              ))}
            </div>
          </div>
          <div className={s.card}>
            <h3 className={s.cardTitle}>
              {' '}
              <span className={cn(s.icon, s.icon_vendings)}></span>
              Виды вендинга, с&nbsp;которыми&nbsp;мы&nbsp;работаем
            </h3>
            <div className={s.cardItems}>
              {VENDINGS.map((el) => (
                <span key={el} className={s.cardItem}>
                  {el}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Technologies
