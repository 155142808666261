import s from './Tariffs.module.scss'
import cn from 'classnames'
const cards = [
  {
    title: 'Прачечные самообслуживания',
    device: 'LAND X.X, TABLET X.X',
    price: '275',
  },
  {
    title: 'Выдача штучного товара',
    device: 'DISP X.X',
    price: '145',
  },
  {
    title: 'Принтер самообслуживания',
    device: 'PRINT X.X',
    price: '850',
  },
  {
    title: 'Макет железной дороги',
    device: 'TRAIN X.X',
    price: '850',
  },
]
const Tariffs = () => {
  return (
    <section className={s.container} id="tariffs">
      <div className={s.content}>
        <h2 className={s.title}>
          Тарифы за&nbsp;использование{' '}
          <span className={s.accent}>телеметрии</span>
        </h2>
        <div className={s.cards}>
          {cards.map((card, index) => (
            <div className={s.card} key={index}>
              <h3 className={cn(s.cardText, s.cardText_title)}>{card.title}</h3>
              <span className={cn(s.cardText, s.cardText_device)}>
                {card.device}
              </span>
              <span className={cn(s.cardText, s.cardText_price_erp)}>
                {card.price} <span className={s.cardText_period}>руб/мес</span>
              </span>
              <span className={cn(s.cardText, s.cardText_erp)}>
                стоимость пользования ERP-платформой
              </span>
              <span className={cn(s.cardText, s.cardText_note)}>
                * стоимость за каждое подключенное устройство
              </span>
            </div>
          ))}
          <div className={cn(s.card, s.card_white)}>
            <h3
              className={cn(
                s.cardText,
                s.cardText_title,
                s.cardText_title_sim,
                s.cardText_black,
              )}
            >
              Мульти SIM-карта UniSim
            </h3>
            <span
              className={cn(s.cardText, s.cardText_price_sim, s.cardText_black)}
            >
              390 <span className={s.cardText_period}>руб/мес</span>
            </span>
            <span className={cn(s.cardText, s.cardText_sim, s.cardText_black)}>
              стоимость пользования SIM-картой в течение 1 месяца
            </span>
            <span className={cn(s.cardText, s.cardText_note, s.cardText_black)}>
              * оплата за услугу производится ежемесячно
            </span>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Tariffs
