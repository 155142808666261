import s from './HamburgerMenu.module.scss'
import cn from 'classnames'
import namePath from '../../assets/icons/unimetriq-menu.svg'
import { HashLink as Link } from 'react-router-hash-link'
import { CABINET_LINK, DEMO_LINK, PHONE_LINK } from '../../constatns'

const HamburgerMenu = (props: {
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>
  openMenu: boolean
}) => {
  const handleCloseMenu = () => {
    props.setOpenMenu(false)
  }
  return (
    <div
      className={cn(s.hamburgerMenu, props.openMenu && s.hamburgerMenu_active)}
    >
      <button
        className={s.close}
        onClick={() => {
          props.setOpenMenu(false)
        }}
      ></button>
      <img src={namePath} alt="Логотип Юниметрик" className={s.name} />
      <nav className={s.navigation}>
        <ul className={s.links}>
          <li className={s.listItem}>
            <Link
              to="/#fiscalization"
              className={s.link}
              onClick={handleCloseMenu}
            >
              Функции сервиса
            </Link>
          </li>
          <li className={s.listItem}>
            <Link
              to="/#advantages"
              className={s.link}
              onClick={handleCloseMenu}
            >
              Преимущества
            </Link>
          </li>
          <li className={s.listItem}>
            <Link to="/#tariffs" className={s.link} onClick={handleCloseMenu}>
              Тарифы
            </Link>
          </li>
          <li className={s.listItem}>
            <Link to={DEMO_LINK} className={s.link}>
              Демо-кабинет
            </Link>
          </li>
        </ul>
      </nav>

      <a className={cn(s.link, s.link_phone)} href={PHONE_LINK}>
        <span className={s.phone}></span> +7 (812) 981 55-50
      </a>
      <Link to={CABINET_LINK}>
        <button className={s.button}>Войти</button>
      </Link>
    </div>
  )
}

export default HamburgerMenu
