import React from 'react'
import s from './Contacts.module.scss'

const Contacts = () => {
  return (
    <section className={s.container}>
      <div className={s.content}>
        <h1 className={s.title}>Контактная информация</h1>
        <h2 className={s.subtitle}>
          <span className={s.uppercase}>
            Общество с&nbsp;ограниченной ответственностью
          </span>{' '}
          «Юниметрик»
        </h2>
        <p className={s.text}>ИНН: 7806562689</p>
        <p className={s.text}>ОГРН: 1197847155010</p>
        <p className={s.text}>
          Юридический&nbsp;адрес: 197350, город&nbsp;Санкт-Петербург,
          пр.&nbsp;Королева, дом&nbsp;65, кв.&nbsp;814
        </p>
        <p className={s.text}>
          Генеральный директор ООО «Юниметрик»:
          Бакалов&nbsp;Евгений&nbsp;Сергеевич
        </p>
        <p className={s.text}>
          Контактный E-mail:{' '}
          <a href="mailto:info@unimetriq.com" className={s.link}>
            info@unimetriq.com
          </a>
        </p>
      </div>
    </section>
  )
}

export default Contacts
