import React from 'react'
import s from './Lead.module.scss'
import namePath from '../../assets/icons/unimetriq-mobile.svg'
import macPath from '../../assets/images/mac.png'
import { HashLink as Link } from 'react-router-hash-link'

const Lead = () => {
  return (
    <section className={s.container}>
      <div className={s.content}>
        <div className={s.infoContainer}>
          <img src={namePath} alt="Логотип Юниметрик" className={s.name} />
          <h2 className={s.subtitle}>
            Сервис телеметрии, аналитики, управления вендингом и&nbsp;системами
            самообслуживания
          </h2>{' '}
          <ul className={s.list}>
            <li className={s.listItem}>Аналитика продаж</li>
            <li className={s.listItem}>Фискализация</li>
            <li className={s.listItem}>Онлайн мониторинг</li>
            <li className={s.listItem}>Удаленное управление оборудованием</li>
          </ul>
          <img src={macPath} alt="Демо личного кабинета" className={s.mac} />
          <Link to="#form" className={s.learnMore}>
            Узнать подробнее
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Lead
