import s from './Payments.module.scss'
import cn from 'classnames'
import cashPath from '../../assets/icons/cash.svg'
import qrPath from '../../assets/icons/qr.svg'
import cardPath from '../../assets/icons/card.svg'
import pinPath from '../../assets/icons/pin.svg'
import sbpPath from '../../assets/icons/sbp.svg'
import kaspiPath from '../../assets/icons/kaspi.svg'

const Payments = () => {
  return (
    <section className={s.container}>
      <div className={s.content}>
        <div className={s.textContainer}>
          <h2 className={s.title}>
            Доступные&nbsp;варианты оплаты&nbsp;для&nbsp;конечных
            клиентов&nbsp;с&nbsp;Юниметрик{' '}
            <span className={s.accent}>
              от&nbsp;монетоприемника до&nbsp;СБП
            </span>
          </h2>
          <p className={s.text}>
            С нашей платформой можно собрать любой вариант систем оплат.
          </p>
          <p className={s.text}>
            По нашему опыту – самым популярным является система быстрых платежей
            (СБП) и оплата картами.
          </p>
        </div>
        <div className={s.payments}>
          <div className={cn(s.payment, s.payment_cash)}>
            <img src={cashPath} alt="Наличные" className={s.image} />
            <span className={cn(s.paymentName, s.paymentName_cash)}>
              Наличные
            </span>
          </div>
          <div className={cn(s.payment, s.payment_qr)}>
            <img src={qrPath} alt="QR-код" className={s.image} />
            <span className={cn(s.paymentName, s.paymentName_qr)}>QR-код</span>
          </div>
          <div className={cn(s.payment, s.payment_card)}>
            <img src={cardPath} alt="Банковская карта" className={s.image} />
            <span className={cn(s.paymentName, s.paymentName_card)}>
              Банковская карта
            </span>
          </div>
          <div className={cn(s.payment, s.payment_pin)}>
            <img src={pinPath} alt="PIN-код" className={s.image} />
            <span className={cn(s.paymentName, s.paymentName_pin)}>
              PIN-код
            </span>
          </div>
          <div className={cn(s.payment, s.payment_sbp)}>
            <img src={sbpPath} alt="СБП" className={s.image} />
            <span
              className={cn(
                s.paymentName,
                s.paymentName_sbp,
                s.paymentName_mobile,
              )}
            >
              СБП
            </span>
            <span className={cn(s.paymentName, s.paymentName_sbp)}>
              Система быстрых платежей
            </span>
          </div>
          <div className={cn(s.payment, s.payment_kaspi)}>
            <img src={kaspiPath} alt="KASPI.KZ" className={s.image} />
            <span className={cn(s.paymentName, s.paymentName_kaspi)}>
              KASPI.KZ
            </span>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Payments
