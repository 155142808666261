import { useState } from 'react'
import s from './Advantages.module.scss'
import InputMask from 'react-input-mask'
import cn from 'classnames'
import confirmPath from '../../assets/icons/confirm.svg'
import { HashLink as Link } from 'react-router-hash-link'
import { DEMO_LINK, POLICY_LINK } from '../../constatns'

const Advantages = () => {
  const [phoneInput, setPhoneInput] = useState('')
  const [commentInput, setCommentInput] = useState('')
  const [nameInput, setNameInput] = useState('')
  const [sended, setSended] = useState(false)

  const handleSubmit = (
    evt: React.FormEvent<HTMLFormElement>,
    phone: string,
    name: string,
    comment: string,
  ) => {
    evt.preventDefault()
    console.log({ phone, name, comment })
    setSended(true)
  }

  return (
    <section className={s.container} id="advantages">
      <div className={s.content}>
        <h2 className={s.title}>
          Наши <br />
          <span className={s.accent}>преимущества</span>
        </h2>
        <ol className={s.list}>
          <li className={cn(s.listItem, s.listItem_one)}>
            Собственное производство электронных устройств. Это&nbsp;значит,
            что&nbsp;мы контролируем каждый этап и можем дать честную гарантию
            на&nbsp;свой продукт.
          </li>
          <li className={cn(s.listItem, s.listItem_two)}>
            Подключение к&nbsp;сети различными способами. От&nbsp;WI-FI
            до&nbsp;SIM-карт.
          </li>
          <li className={cn(s.listItem, s.listItem_three)}>
            Постоянная техническая поддержка и штат программистов позволяют
            оперативно решать задачи любого уровня в&nbsp;работе оборудования.
          </li>
          <li className={cn(s.listItem, s.listItem_four)}>
            Мы делаем сервис для&nbsp;себя и, как для&nbsp;себя. Именно поэтому
            следим за&nbsp;качеством и постоянно улучшаем его&nbsp;работу.
          </li>
          <li className={cn(s.listItem, s.listItem_five)}>
            Исходя из&nbsp;п.4 – мы создали уникальный модуль, позволяющий
            контролировать работу сервисменов и буквально за&nbsp;30-40 секунд
            формировать подробный отчет о&nbsp;визитах ваших сотрудников.
          </li>
          <li className={cn(s.listItem, s.listItem_six)}>
            Удобная выгрузка отчетов в&nbsp;различных форматах. Excel, Word,
            PDF.
          </li>
          <li className={cn(s.listItem, s.listItem_seven)}>
            Есть отличная возможность разделять роли в&nbsp;платформе и
            ограничивать или добавлять элементы управления, как
            для&nbsp;арендодателей так и для&nbsp;сервисменов, бухгалтера,
            аналитика и&nbsp;др.
          </li>
          <li className={cn(s.listItem, s.listItem_eight)}>
            В 2023 году мы получили Премию VASTA на&nbsp;международной выставке
            вендинга и систем самообслуживания за&nbsp;лучшее решение
            в&nbsp;Вендинге.
          </li>
        </ol>
        <Link to={DEMO_LINK} className={s.button}>
          Перейти в демо-кабинет
        </Link>
        <form
          className={s.form}
          onSubmit={(evt) => {
            handleSubmit(evt, phoneInput, nameInput, commentInput)
          }}
        >
          <div className={s.formHeader}>
            <span className={s.formTitle}>
              Остались <span className={s.accent}>вопросы?</span>
            </span>
            <p className={s.formText}>
              Оставьте заявку, мы с&nbsp;вами свяжемся в&nbsp;ближайшее время и
              поможем оформить бесплатный доступ на&nbsp;30&nbsp;дней
            </p>
          </div>
          <div className={s.formMain} id="form">
            <div
              className={cn(
                s.notification,
                !sended && s.notification_invisible,
              )}
            >
              <img className={s.confitm} src={confirmPath} alt="Галочка" />
              <span className={s.confirmText}>Заявка отправлена!</span>
              <span className={cn(s.confirmText, s.confirmText_small)}>
                Специалист свяжется с&nbsp;вами в&nbsp;ближайшее время
              </span>
            </div>
            <label className={s.label}>
              <span className={s.labelText}>Как к вам можно обращаться? *</span>
              <input
                type="text"
                className={s.input}
                placeholder="Ваше имя"
                required
                onChange={(evt) => {
                  setNameInput(evt.target.value)
                }}
                value={nameInput}
              ></input>
            </label>
            <label className={s.label}>
              <span className={s.labelText}>Номер телефона *</span>
              <InputMask
                mask="+7 (999) 999 99-99"
                placeholder="+7 (999) 999 99-99"
                onChange={(evt) => {
                  setPhoneInput(evt.target.value)
                }}
                value={phoneInput}
                className={s.input}
                required
              />
            </label>
            <label className={cn(s.label, s.label_comment)}>
              <span className={s.labelText}>Комментарий</span>
              <textarea
                onChange={(evt) => {
                  setCommentInput(evt.target.value)
                }}
                value={commentInput}
                className={s.textArea}
                placeholder=""
              ></textarea>
            </label>
            <p className={cn(s.formText, s.formText_gray)}>
              Оставляя заявку на сайте, вы соглашаетесь с{' '}
              <Link to={POLICY_LINK} target="_blank" className={s.link}>
                политикой конфиденциальности
              </Link>
            </p>
            <button type="submit" className={s.submit}>
              {' '}
              Оставить заявку
            </button>
          </div>
        </form>
      </div>
    </section>
  )
}

export default Advantages
