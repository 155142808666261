import React, { useState } from 'react'
import s from './Documentation.module.scss'
import cn from 'classnames'
import Collapsible from 'react-collapsible'
import { useNavigate } from 'react-router-dom'
import { GUIDE_LINK, INSTRUCTION_LINK } from '../../constatns'
import { Link } from 'react-router-dom'

const Documentation = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  }
  // const navigate = useNavigate()
  return (
    <section className={s.container}>
      <div className={s.content}>
        <h1 className={s.title}>Документация по продукту</h1>
        <div className={s.grid}>
          <div className={s.card}>
            <span className={cn(s.cardText, s.cardText_title)}>
              Инструкция по установке
            </span>
            <span className={cn(s.cardText, s.cardText_type)}>PDF, 0,5 МБ</span>
            {/* <button className={s.button}>
              Скачать <span className={cn(s.icon, s.icon_download)}></span>
            </button> */}
            <Link
              className={cn(s.button, s.button_white)}
              to={INSTRUCTION_LINK}
              target="_blank"
            >
              Посмотреть <span className={cn(s.icon, s.icon_eye)}></span>
            </Link>
          </div>
          <div className={s.card}>
            <span className={cn(s.cardText, s.cardText_title)}>
              Руководство пользователя
            </span>
            <span className={cn(s.cardText, s.cardText_type)}>PDF, 7 МБ</span>
            {/* <button className={s.button}>
              Скачать <span className={cn(s.icon, s.icon_download)}></span>
            </button> */}
            <Link
              className={cn(s.button, s.button_white)}
              to={GUIDE_LINK}
              target="_blank"
            >
              Посмотреть <span className={cn(s.icon, s.icon_eye)}></span>
            </Link>
          </div>
          {/* <div className={s.card}>
            <span className={cn(s.cardText, s.cardText_title)}>
              Презентация Юниметрик
            </span>
            <span className={cn(s.cardText, s.cardText_type)}>PDF, 10 МБ</span>
            <button className={s.button}>
              Скачать <span className={cn(s.icon, s.icon_download)}></span>
            </button>
            <button className={cn(s.button, s.button_white)}>
              Посмотреть <span className={cn(s.icon, s.icon_eye)}></span>
            </button>
          </div> */}
        </div>
        {/* <Collapsible
          className={s.accordion}
          openedClassName={s.accordion}
          trigger={
            <span className={s.triggerSpan}>
              Состав технологий{' '}
              <span className={cn(s.expand, isOpen && s.expand_opened)}></span>
            </span>
          }
          open={isOpen}
          handleTriggerClick={toggleAccordion}
          triggerClassName={s.trigger}
          triggerOpenedClassName={s.trigger}
          easing="ease-in-out"
        >
          <div onClick={toggleAccordion} className={s.innerContainer}>
            {' '}
            <ul className={s.accordionList}>
              <li>Frontend: JS, React;</li>
              <li>
                Backend: PHP&nbsp;8.3, Laravel&nbsp;9.52.16, 10.48.2, 11.1.0;
              </li>
              <li>База данных: MySQL&nbsp;8.3</li>
              <li>Веб-сервер Nginx&nbsp;1.18.0</li>
              <li>
                Сборщик frontend части NodeJS&nbsp;20.11.0, Npm&nbsp;10.2.4
              </li>
              <li>ОС Debian 11 или аналог</li>
            </ul>
            <p className={s.accordionText}>Минимальные требования:</p>
            <ul className={cn(s.accordionList, s.accordionList_last)}>
              <li>
                4 виртуальных процессора частотой не&nbsp;менее&nbsp;2.7GHz
              </li>
              <li>16GB оперативной памяти</li>
              <li>
                50GB дискового хранилища SSD с&nbsp;IOPS не&nbsp;менее 3000
                на&nbsp;чтение и на&nbsp;запись
              </li>
            </ul>
          </div>
        </Collapsible> */}
      </div>
    </section>
  )
}

export default Documentation
