import React from 'react'
import Lead from '../Lead/Lead'
import Info from '../Info/Info'
import Vendinds from '../Vendinds/Vendinds'
import Payments from '../Payments/Payments'
import Functions from '../Functions/Functions'
import Advantages from '../Advantages/Advantages'
import Tariffs from '../Tariffs/Tariffs'
import Activity from '../Activity/Activity'
import Technologies from '../Technologies/Technologies'

const Main = () => {
  return (
    <>
      <Lead />
      <Info />
      <Activity />
      <Vendinds />
      <Payments />
      <Functions />
      <Advantages />
      <Tariffs />
      <Technologies />
    </>
  )
}

export default Main
