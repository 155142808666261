import s from './Functions.module.scss'
import fiscalizationPath from '../../assets/images/fiscalization.svg'
import monitoringPath from '../../assets/images/monitoring.svg'
import analyticsPath from '../../assets/images/analytics.svg'
import managementPath from '../../assets/images/management.svg'
import cn from 'classnames'

import { HashLink as Link } from 'react-router-hash-link'

const Fiscalization = () => {
  return (
    <section className={s.container} id="fiscalization">
      <div className={s.content}>
        <h2 className={s.title}>
          Функции <span className={s.accent}>сервиса</span>
        </h2>
        <div className={s.itemsContainer}>
          <div
            className={cn(s.functionContainer, s.functionContainer_analytics)}
          >
            <div className={s.imageContainer}>
              <img
                src={analyticsPath}
                alt="Аналитиика продаж"
                className={cn(s.image, s.image_analytics)}
              />
            </div>
            <h3 className={cn(s.subtitle)}>Аналитика продаж</h3>
            <p className={cn(s.text, s.text_small)}>
              Помогает владельцам оборудования отслеживать и анализировать
              ключевые параметры бизнеса.
            </p>
          </div>
          <div
            className={cn(s.functionContainer, s.functionContainer_monitoring)}
          >
            <div className={s.imageContainer}>
              <img
                src={monitoringPath}
                alt="Онлайн-мониторинг"
                className={cn(s.image, s.image_monitoring)}
              />
            </div>
            <h3 className={cn(s.subtitle)}>Онлайн-мониторинг</h3>
            <p className={cn(s.text, s.text_small)}>
              Позволяет отслеживать количество денежных средств и товара
              в&nbsp;оборудовании, а&nbsp;также помогает увеличить эффективность
              работы сервисменов.
            </p>
          </div>
          <div
            className={cn(s.functionContainer, s.functionContainer_management)}
          >
            <div className={s.imageContainer}>
              <img
                src={managementPath}
                alt="Удаленное управление"
                className={cn(s.image, s.image_management)}
              />
            </div>
            <h3 className={cn(s.subtitle)}>Удаленное управление</h3>
            <p className={cn(s.text, s.text_small)}>
              Помогает в&nbsp;любое время проверить работоспособность
              оборудования, заполненность, а&nbsp;также помочь конечному
              клиенту, если случилась поломка.
            </p>
          </div>
          <div
            className={cn(
              s.functionContainer,
              s.functionContainer_fiscalization,
            )}
          >
            <div className={s.imageContainer}>
              <img
                src={fiscalizationPath}
                alt="Фискализация"
                className={cn(s.image, s.image_fiscalization)}
              />
            </div>
            <h3 className={cn(s.subtitle)}>Фискализация</h3>
            <p className={cn(s.text, s.text_small)}>
              По закону 54–ФЗ в&nbsp;каждой системе самообслуживания важно
              устанавливать онлайн кассу и выдавать чеки клиенту. С&nbsp;нашей
              платформой – это возможно подключить в&nbsp;пару кликов.
            </p>
          </div>
          <div className={s.fiscalizationContainer}>
            <div className={s.fiscalizationTextContainer}>
              <h3 className={cn(s.subtitle, s.subtitle_fiscalization)}>
                Фискализация
              </h3>
              <p className={cn(s.text, s.text_fiscalization)}>
                По&nbsp;закону 54–ФЗ в&nbsp;каждой системе самообслуживания
                важно устанавливать онлайн кассу и выдавать чеки клиенту.
              </p>
              <p className={cn(s.text, s.text_fiscalization)}>
                С&nbsp;нашей платформой – это&nbsp;возможно подключить
                в&nbsp;пару кликов.
              </p>
              <p className={cn(s.text, s.text_fiscalization)}>
                Онлайн касса и фискализация уже&nbsp;«предустановлены».
                Это&nbsp;значит, что вам не&nbsp;нужно дополнительно
                что-то&nbsp;докупать из&nbsp;стороннего «железа» и
                устанавливать. Достаточно подписать договор.
              </p>
              <Link to="#form" className={cn(s.button, s.button_fiscalization)}>
                Хочу подключиться
              </Link>
            </div>
            <img
              src={fiscalizationPath}
              alt="Фискализация"
              className={s.fiscalizationImage}
            />
          </div>
        </div>
        <div className={s.note}>
          <span className={s.noteIcon}></span>
          <p className={s.text}>
            Онлайн касса и фискализация уже&nbsp;«предустановлены».
            Это&nbsp;значит, что&nbsp;вам не&nbsp;нужно дополнительно что-то
            докупать из&nbsp;стороннего «железа» и устанавливать. Достаточно
            подписать договор.
          </p>
        </div>
        <Link to="#form" className={cn(s.button, s.button_mobile)}>
          Хочу подключиться
        </Link>
      </div>
    </section>
  )
}

export default Fiscalization
