import React from 'react'
import s from './Header.module.scss'
import logoPath from '../../assets/icons/header-logo.svg'
import cn from 'classnames'
import { HashLink as Link } from 'react-router-hash-link'
import { useNavigate } from 'react-router-dom'
import { CABINET_LINK, DEMO_LINK, PHONE_LINK } from '../../constatns'

const Header = (props: {
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const navigate = useNavigate()
  return (
    <header className={s.container} id="header">
      <div className={s.content}>
        <img
          src={logoPath}
          alt="Логотип"
          className={s.logo}
          onClick={() => {
            navigate('/')
          }}
        />
        <nav className={s.navigation}>
          <ul className={s.links}>
            <li className={s.listItem}>
              <Link to="/#fiscalization" className={s.link}>
                Функции сервиса
              </Link>
            </li>
            <li className={s.listItem}>
              <Link to="/#advantages" className={s.link}>
                Преимущества
              </Link>
            </li>
            <li className={s.listItem}>
              <Link to="/#tariffs" className={s.link}>
                Тарифы
              </Link>
            </li>
            <li className={s.listItem}>
              <Link to={DEMO_LINK} className={s.link}>
                Демо-кабинет
              </Link>
            </li>
          </ul>
        </nav>

        <a className={cn(s.link, s.link_phone)} href={PHONE_LINK}>
          <span className={s.phone}></span> +7 (812) 981 55-50
        </a>
        <Link to={CABINET_LINK}>
          <button className={s.button}>Войти</button>
        </Link>
        <button
          className={s.hamburger}
          onClick={() => {
            props.setOpenMenu(true)
          }}
        ></button>
      </div>
    </header>
  )
}

export default Header
