import s from './Footer.module.scss'
import cn from 'classnames'
import logoPath from '../../assets/icons/footer-logo.svg'
import { Link, useNavigate } from 'react-router-dom'
import { POLICY_LINK } from '../../constatns'
const date = new Date().getFullYear()

const Footer = () => {
  const navigate = useNavigate()
  return (
    <footer className={s.container}>
      <div className={s.content}>
        <img
          src={logoPath}
          alt="Логотип"
          className={s.logo}
          onClick={() => {
            navigate('/', {})
          }}
        ></img>

        <span className={cn(s.text, s.text_address)}>
          Санкт-Петербург, Особняк&nbsp;общества&nbsp;Пальма
          Пирогова&nbsp;18,&nbsp;офис&nbsp;402
        </span>
        <button
          className={s.button}
          onClick={() => {
            navigate('/documents')
          }}
        >
          Документация по продукту
        </button>
        <span className={cn(s.text, s.text_link, s.text_contacts)}>
          <Link to={'/contacts'} className={s.link}>
            Контактная информация
          </Link>
        </span>
        <span className={cn(s.text, s.text_link)}>
          <Link to={POLICY_LINK} target="_blank" className={s.link}>
            Политика конфиденциальности
          </Link>
        </span>
        <span className={s.copyright}>&#169; ООО «Юниметрик», 2019-{date}</span>
      </div>
    </footer>
  )
}

export default Footer
