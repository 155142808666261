import s from './Activity.module.scss'
import certificatePath from '../../assets/images/certificate.jpg'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import { DEMO_LINK } from '../../constatns'

const Activity = () => {
  return (
    <section className={s.container}>
      <div className={s.content}>
        <div className={s.textContainer}>
          <h2 className={s.title}>
            <span className={s.accent}>Деятельность сайта unimetriq.com</span>{' '}
            осуществляется в&nbsp;рамках ОКВЭД&nbsp;62.01: разработка
            компьютерного программного обеспечения
          </h2>
          <p className={s.text}>
            В рамках данного вида деятельности мы оказываем следующие услуги:
          </p>
          <ul className={s.list}>
            <li className={s.listItem}>
              Проектирование и разработка программного обеспечения
            </li>
            <li className={s.listItem}>
              Внедрение разработанного программного обеспечения
              в&nbsp;бизнес-процессы и сопровождение
            </li>
            <li className={s.listItem}>Техническая поддержка.</li>
          </ul>
          <p className={s.text}>
            Программное обеспечение "UnimetriQ&nbsp;- сервис телеметрии
            для&nbsp;систем самообслуживания" включено в&nbsp;Единый реестр
            российских программ для&nbsp;ЭВМ и баз данных.
            <br /> Реестровая запись №&nbsp;22994 от&nbsp;28.06.2024
          </p>
        </div>
        <div className={s.certificateContainer}>
          <img
            src={certificatePath}
            alt="Свидетельство ЭВМ"
            className={s.certificate}
          />
        </div>
        <Link to={DEMO_LINK} className={cn(s.button, s.button_mobile)}>
          Перейти в демо-кабинет
        </Link>
      </div>
    </section>
  )
}

export default Activity
